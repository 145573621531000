export const arhitekturaInfo = [
  {
    id: 1,
    src: "/images/arhitektura/autobuska_stanica/1.jpg",
    text: "// Autobuska Stanica | Bijeljina 2019",
    folder: "autobuska_stanica",
    imgCount: 7,
    info: "Klijent: Grad Bijeljina, Godina projektovanja: 2019, Dimenzije: 2120 m2, Lokacija: Bijeljina BiH",
  },
  {
    id: 2,
    src: "/images/arhitektura/sava_centar/1.jpg",
    text: "// Sava Centar | Bijeljina 2022",
    folder: "sava_centar",
    imgCount: 9,
    info: "Klijent: Sava Centar, Godina projektovanja: 2022, Dimenzije: 110000 m2, Lokacija: Bijeljina BiH",
  },
  {
    id: 3,
    src: "/images/arhitektura/stanisic_kuca/1.jpg",
    text: "// Porodična kuća | Bijeljina 2024",
    folder: "stanisic_kuca",
    imgCount: 7,
    info: "Klijent: Stanišić , Godina projektovanja: 2024, Dimenzije: 2000 m2, Lokacija: Bijeljina BiH",
  },
  {
    id: 4,
    src: "/images/arhitektura/rb_centar/1.jpg",
    text: "// RB izgradnja | Bijeljina 2022",
    folder: "rb_centar",
    imgCount: 4,
    info: "Klijent: RB D.O.O., Godina projektovanja: 2022, Dimenzije: 2300 m2, Lokacija: Bijeljina BiH",
  },
  {
    id: 5,
    src: "/images/arhitektura/vodoprivreda/1.jpg",
    text: "// Zavod za vodoprivredu | Bijeljina 2022",
    folder: "vodoprivreda",
    imgCount: 5,
    info: "Klijent: D.O.O. Zavod za vodoprivredu, Godina projektovanja: 2022, Dimenzije: 3300 m2, Lokacija: Bijeljina BiH",
  },
  {
    id: 6,
    src: "/images/arhitektura/finalprom/1.jpg",
    text: "// Finalprom  | Bijeljina 2022",
    folder: "finalprom",
    imgCount: 4,
    info: "Klijent: D.O.O. Finalprom, Godina projektovanja: 2022, Dimenzije: 2700 m2, Lokacija: Bijeljina BiH",
  },
  {
    id: 7,
    src: "/images/arhitektura/veselinovic_golija/1.jpg",
    text: "// Elvaco MetPro | Bijeljina 2021",
    folder: "veselinovic_golija",
    imgCount: 5,
    info: "Klijent: Elvaco MetPro, Godina projektovanja: 2021, Dimenzije: 300 m2, Lokacija: Bijeljina BiH",
  },
  {
    id: 8,
    src: "/images/arhitektura/trb_ristic/1.jpg",
    text: "// Stambeni objekat | Bijeljina 2021",
    folder: "trb_ristic",
    imgCount: 6,
    info: "Klijent: Slavenko Ristić, Godina projektovanja: 2021, Dimenzije: 300 m2, Lokacija: Bijeljina BiH",
  },
  {
    id: 9,
    src: "/images/arhitektura/promenada_3/1.jpg",
    text: "// Stambeno poslovni objekat | Bijeljina 2024",
    folder: "promenada_3",
    imgCount: 4,
    info: "Klijent: Mihajlović Invest, Godina projektovanja: 2023, Dimenzije: 12500 m2, Lokacija: Bijeljina BiH",
  },
  {
    id: 10,
    src: "/images/arhitektura/promenada/1.jpg",
    text: "// Stambeno poslovni objekat | Bijeljina 2021",
    folder: "promenada",
    imgCount: 3,
    info: "Klijent: Mihajlović Invest, Godina projektovanja: 2021, Dimenzije: 6500 m2, Lokacija: Bijeljina BiH",
  },
  {
    id: 11,
    src: "/images/arhitektura/zgrada_mihajlovic_invest/1.jpg",
    text: "// Stambeno poslovni objekat | Bijeljina 2020",
    folder: "zgrada_mihajlovic_invest",
    imgCount: 4,
    info: "Klijent: Mihajlović Invest, Godina projektovanja: 2020, Dimenzije: 8035 m2, Lokacija: Bijeljina BiH",
  },
  {
    id: 12,
    src: "/images/arhitektura/sabac/1.jpg",
    text: "// Porodični objekat | Šabac 2021",
    folder: "sabac",
    imgCount: 5,
    info: "Klijent: n/a, Godina projektovanja: 2021, Dimenzije: 600 m2, Lokacija: Šabac RS",
  },
  {
    id: 13,
    src: "/images/arhitektura/pribisic/1.jpg",
    text: "// Stambeno poslovni objekat | Bijeljina 2019",
    folder: "pribisic",
    imgCount: 5,
    info: "Klijent: Hotel Krug, Godina projektovanja: 2019, Dimenzije: 3600 m2, Lokacija: Bijeljina BiH",
  },
  {
    id: 14,
    src: "/images/arhitektura/hotel_stara_hercegovina/1.jpg",
    text: "/ Zdravstveno turistički centar | Banja Vrućica 2020",
    folder: "hotel_stara_hercegovina",
    imgCount: 5,
    info: "Klijent: Hotel Stara Hercegovina, Godina projektovanja: 2020, Dimenzije: 6500 m2, Lokacija: Banja Vrućica BiH",
  },
  {
    id: 15,
    src: "/images/arhitektura/lazarevic/1.jpg",
    text: "// Stambeni objekat | Bijeljina 2019",
    folder: "lazarevic",
    imgCount: 4,
    info: "Klijent: Lazarević, Godina projektovanja: 2019, Dimenzije: 150 m2, Lokacija: Bijeljina BiH",
  },
  {
    id: 16,
    src: "/images/arhitektura/blagojevic_kuca/1.jpg",
    text: "// Stambeni objekat | Bijeljina 2020",
    folder: "blagojevic_kuca",
    imgCount: 7,
    info: "Klijent: Blagojević, Godina projektovanja: 2020, Dimenzije: 735 m2, Lokacija: Bijeljina BiH",
  },
  {
    id: 17,
    src: "/images/arhitektura/triomax/1.jpg",
    text: "// Triomax | Bijeljina 2020",
    folder: "triomax",
    imgCount: 3,
    info: "Klijent: Triomax, Godina projektovanja: 2020, Dimenzije: 512 m2, Lokacija: Bijeljina BiH",
  },
  {
    id: 18,
    src: "/images/arhitektura/intergaj_racanska/1.jpg",
    text: "// Stambeno poslovni objekat | Bijeljina 2020",
    folder: "intergaj_racanska",
    imgCount: 6,
    info: "Klijent: Intergaj, Godina projektovanja: 2020, Dimenzije: 3524 m2, Lokacija: Bijeljina BiH",
  },
  {
    id: 19,
    src: "/images/arhitektura/zubart/1.jpg",
    text: "// Poslovni objekat | Bijeljina 2019",
    folder: "zubart",
    imgCount: 3,
    info: "Klijent: Zubart, Godina projektovanja: 2019, Dimenzije: 380 m2, Lokacija: Bijeljina BiH",
  },
  {
    id: 20,
    src: "/images/arhitektura/huerliman/1.jpg",
    text: "// Stambeni objekat | Zvornik 2019",
    folder: "huerliman",
    imgCount: 3,
    info: "Klijent: Huerliman, Godina projektovanja: 2019, Dimenzije: 600 m2, Lokacija: Zvornik BiH",
  },
  {
    id: 21,
    src: "/images/arhitektura/pelemis/1.jpg",
    text: "// Poslovni objekat | Bijeljina 2019",
    folder: "pelemis",
    imgCount: 4,
    info: "Klijent: Motel 55, Godina projektovanja: 2019, Dimenzije: 400 m2, Lokacija: Bijeljina BiH",
  },
  {
    id: 22,
    src: "/images/arhitektura/stevanovic/1.jpg",
    text: "// Stambeni objekat | Bijeljina 2017",
    folder: "stevanovic",
    imgCount: 6,
    info: "Klijent: Stevanović, Godina projektovanja: 2017, Dimenzije: 420 m2, Lokacija: Bijeljina BiH",
  },
  {
    id: 23,
    src: "/images/arhitektura/tesici_kuca/1.jpg",
    text: "// Stambeni objekat | Bijeljina 2018",
    folder: "tesici_kuca",
    imgCount: 4,
    info: "Klijent: Tesić, Godina projektovanja: 2018, Dimenzije: 280 m2, Lokacija: Bijeljina BiH",
  },
  {
    id: 24,
    src: "/images/arhitektura/finalprom_2/1.jpg",
    text: "// Finalprom | Bijeljina 2018",
    folder: "finalprom_2",
    imgCount: 6,
    info: "Klijent: D.O.O. Finalprom, Godina projektovanja: 2018, Dimenzije: 1200 m2, Lokacija: Bijeljina BiH",
  },
];
