export const enterijeriInfo = [
  {
    id: 1,
    src: "/images/enterijeri/fantasia/1.jpg",
    text: "// Fantasia | Bijeljina 2023",
    folder: "fantasia",
    imgCount: 13,
    info: "Klijent: Restoran Fantasia, Godina projektovanja: 2023, Dimenzije: 861.21  m2, Lokacija: Bijeljina BiH",
  },
  {
    id: 2,
    src: "/images/enterijeri/monarh/1.jpg",
    text: "// Monarh| Bijeljina 2021",
    folder: "monarh",
    imgCount: 9,
    info: `Klijent: Cafe restoran Monarh ,
       Godina projektovanja: 2021,Dimenzije: 193 m2,  
     Lokacija: Bijeljina BiH`,
  },
  {
    id: 3,
    src: "/images/enterijeri/sofa/1.jpg",
    text: "// Sofa| Bijeljina 2021",
    folder: "sofa",
    imgCount: 13,
    info: "Klijent: Sofa restoran, Godina projektovanja: 2021, Dimenzije: 180.23 m2, Lokacija: Bijeljina BiH",
  },
  {
    id: 4,
    src: "/images/enterijeri/portopiccolo/1.jpg",
    text: "// Portopiccolo | Bijeljina 2020",
    folder: "portopiccolo",
    imgCount: 8,
    info: "Klijent: Portopiccolo, Godina projektovanja: 2020, Dimenzije: 125 m2, Lokacija: Bijeljina BiH",
  },
  {
    id: 5,
    src: "/images/enterijeri/laganini/1.jpg",
    text: "// Laganini | Bijeljina 2020",
    folder: "laganini",
    imgCount: 11,
    info: "Klijent: Laganini, Godina projektovanja: 2020, Dimenzije: 120 m2 Lokacija: Bijeljina BiH",
  },
  {
    id: 6,
    src: "/images/enterijeri/garden_pub/1.jpg",
    text: "// Garden Pub | Bijeljina 2018",
    folder: "garden_pub",
    imgCount: 10,
    info: "Klijent: Garden Pub, Godina projektovanja: 2018, Dimenzije: 66 m2, Lokacija: Bijeljina BiH",
  },
  {
    id: 7,
    src: "/images/enterijeri/mihajlovic_kancelarije/1.jpg",
    text: "// Mihajlovic | Bijeljina 2020",
    folder: "mihajlovic_kancelarije",
    imgCount: 7,
    info: "Klijent: Mihajlovic, Godina projektovanja: 2020, Dimenzije: 220 m2, Lokacija: Bijeljina BiH",
  },
  {
    id: 8,
    src: "/images/enterijeri/mega_drvo_kancelarije/1.jpg",
    text: "// Megadrvo | Bijeljina 2020",
    folder: "mega_drvo_kancelarije",
    imgCount: 8,
    info: "Klijent: Megadrvo, Godina projektovanja: 2020, Dimenzije: 350 m2, Lokacija: Bijeljina BiH",
  },
  {
    id: 9,
    src: "/images/enterijeri/urban_pub_krug/1.jpg",
    text: "// Urban pub Krug | Bijeljina 2020",
    folder: "urban_pub_krug",
    imgCount: 5,
    info: "Klijent: Urban pub Krug, Godina projektovanja: 2020, Dimenzije: 66 m2, Lokacija: Bijeljina BiH",
  },
  {
    id: 10,
    src: "/images/enterijeri/dijana_s/1.jpg",
    text: "// Dijana S | Bijeljina 2018",
    folder: "dijana_s",
    imgCount: 5,
    info: "Klijent: Dijana S, Godina projektovanja: 2018, Dimenzije: 66 m2, Lokacija: Bijeljina BiH",
  },
  {
    id: 11,
    src: "/images/enterijeri/cuk_kuca/1.jpg",
    text: "// Ćuk | Bijeljina 2019",
    folder: "cuk_kuca",
    imgCount: 5,
    info: "Klijent: Ćuk, Godina projektovanja: 2019, Dimenzije: 200 m2, Lokacija: Bijeljina BiH",
  },
  {
    id: 12,
    src: "/images/enterijeri/le_jardine/1.jpg",
    text: "// Le Jardine | Šabac 2020",
    folder: "le_jardine",
    imgCount: 6,
    info: "Klijent: Le Jardine, Godina projektovanja: 2019, Dimenzije: 66 m2, Lokacija: Šabac RS",
  },
  {
    id: 13,
    src: "/images/enterijeri/cvetinovic/1.jpg",
    text: "// Cvetinović | Bijeljina 2019",
    folder: "cvetinovic",
    imgCount: 6,
    info: "Klijent: Cvetinović, Godina projektovanja: 2019, Dimenzije: 117 m2, Lokacija: Bijeljina BiH",
  },
  {
    id: 14,
    src: "/images/enterijeri/kontakt/1.jpg",
    text: "// Contact | Bijeljina 2017",
    folder: "kontakt",
    imgCount: 4,
    info: "Klijent: Kontakt, Godina projektovanja: 2017, Dimenzije: 60 m2, Lokacija: Bijeljina BiH",
  },
  {
    id: 15,
    src: "/images/enterijeri/hotel_jelena/1.jpg",
    text: "// Hotel Jelena | Brčko 2019",
    folder: "hotel_jelena",
    imgCount: 4,
    info: "Klijent: Hotel Jelena, Godina projektovanja: 2019, Dimenzije: 1350 m2, Lokacija: Brčko BiH",
  },
  {
    id: 16,
    src: "/images/enterijeri/grubac/1.jpg",
    text: "// Grubač | Bijeljina 2016",
    folder: "grubac",
    imgCount: 6,
    info: "Klijent: Grubač, Godina projektovanja: 2016, Dimenzije: 150 m2, Lokacija: Bijeljina BiH",
  },
  {
    id: 17,
    src: "/images/enterijeri/petrovic/1.jpg",
    text: "// Petrović | Bijeljina 2018",
    folder: "petrovic",
    imgCount: 6,
    info: "Klijent: Petrović, Godina projektovanja: 2018, Dimenzije: 230 m2, Lokacija: Bijeljina BiH",
  },
  {
    id: 18,
    src: "/images/enterijeri/mihajlovic/1.jpg",
    text: "// Mihajlović | Bijeljina 2018",
    folder: "mihajlovic",
    imgCount: 8,
    info: "Klijent: Mihajlović, Godina projektovanja: 2018, Dimenzije: 250 m2, Lokacija: Bijeljina BiH",
  },
  {
    id: 19,
    src: "/images/enterijeri/koprivica/1.jpg",
    text: "// Koprivica | Bijeljina 2018",
    folder: "koprivica",
    imgCount: 5,
    info: "Klijent: Koprivica, Godina projektovanja: 2018, Dimenzije: 90 m2, Lokacija: Bijeljina BiH",
  },
];
